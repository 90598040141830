.custom-table-style th,
.custom-table-style td {
  cursor: default !important;
  white-space: normal !important;
}

.custom-table-style tr td:nth-child(2) {
  min-width: 160px !important;
}

.custom-table-style tr td:first-child {
  min-width: 140px !important;
}

.custom-navbar-style {
  box-shadow: 0 0.8rem 1.2rem 0 rgb(206, 212, 218, 40%) !important;
}

.custom-card-style {
  box-shadow: 1rem 1rem 1rem 1rem rgb(206, 212, 218, 40%) !important;
}


.custom-card-header {
  font-size: 1.5rem;
}


@media (max-width: 768px) {
  .custom-navbar-style {
    display: none !important;
  }

  .custom-margin-top {
    padding-top: 50px !important;
    padding-bottom: 80px !important;
  }

  .custom-card-header {
    font-size: 1.2rem;
  }

  .custom-btn-style {
    width: 100% !important;
  }

  .custom-table-style td .w-25 {
    margin-right: 15px !important;
  }

}

.custom-container {
  margin-left: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.custom-sidebar-item .nav-link:hover,
.custom-sidebar-item .nav-link:hover i,
.custom-sidebar-item .nav-link:active,
.custom-sidebar-item .nav-link:active i,
.custom-sidebar-item .nav-link.active,
.custom-sidebar-item .nav-link.active i {
  color: #1171ef !important;
}

.bg-custom {
  background-color: #ECF0F6;
}

.custom-navbar-nav {
  margin-left: 0 !important;
  margin-right: 0 !important;
}


@media (min-width: 768px) {
  .custom-sidebar-navbar-style {
    display: none !important;
  }

  .custom-sidebar-item {
    padding-top: 80px !important;
  }

  .custom-sidebar-item .nav-link {
    font-size: .9rem !important;
    font-weight: 600 !important;
  }

  .custom-margin-top {
    padding-top: 100px !important;
    padding-bottom: 50px !important;
  }

  .custom-width {
    max-width: 4rem !important;
  }

  .custom-main-content {
    margin-left: 4rem !important;
  }

  .custom-navbar.container-fluid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }


  .extra-sidebar-style {
    box-shadow: 0 0.8rem 1.2rem 0 rgb(206, 212, 218, 40%) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .custom-d-none {
    display: none !important;
  }

  .custom-btn-style.custom-float-btn {
    float: right !important;
  }

  .custom-form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
}

.main-content {
  min-height: 100vh;
}

.custom-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 0 !important;
  box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
}


.counter-text-style {
  font-size: 2rem !important;
}

@media (min-width: 1160px) and (max-width: 1425px) {
  .counter-text-style {
    font-size: 1.5rem !important;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

button.custom-navbar-toggler {
  width: 60px !important;
}

.custom-sidebar-toggler {
  padding: 0.25rem 0.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  width: 40px;

}

.custom-sidebar-toggler:hover,
.custom-sidebar-toggler:focus {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}



/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8898AA;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8898AA;
}


.profile-rounded-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: transparent;
  border: 1px solid #c7cbd0;
}

.profile-circle-name {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}

.profile-rounded-circle-sm {
  font-size: 0.8375rem;
  line-height: 1.2rem;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: 1px solid #c7cbd0;
}


.inspector-tabset {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: space-around;
  border-bottom: 1px solid #edf1f7;
}



.inspector-tabset-tab {
  margin-bottom: -1px;
  text-align: center;
  position: relative;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.inspector-tabset-tab .tab--link {
  display: flex;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 2rem;
  /* font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem; */
  text-transform: uppercase;
}

.hover-pointer {
  cursor: pointer;
}

.inspector-tabset-tab.active .tab--link,
.inspector-tabset-tab:hover .tab--link {
  background-color: transparent;
  color: #3366ff;
  border-bottom: 2px solid #3366ff;
}


.bg-notification {
  background: rgb(0, 214, 143) !important;
}


.bg-payment {
  background: rgb(51, 102, 255) !important;
}

.bg-bank {
  background: rgb(203, 59, 124) !important;
}


.custom-transition {
  transition: width 0.4s;
}


.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}


.detail-text-style {
  padding: 0.2rem 0.5rem;
  border-radius: 6px;
  vertical-align: middle;
}


.custom-dropdown-menu {
  position: absolute !important;
  width: 100%;
}


.custom-navbar-search {
  border-color: #c7cbd0 !important;
  border-radius: 0.5rem !important;
  border: 1px solid !important;
}


.set-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.custom-column-style {
  padding: 0;
  margin: 0;
}

.custom-column-style>div {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.split-line {
  width: 100%;
  height: 90%;
  border-bottom: 1px solid #898989;
  transform: rotate(-20deg);
  position: absolute;
  /* z-index: -1; */
}


.split-line-bottom {
  position: absolute;
  bottom: 1px;
  left: 1px;
}

.split-line-top {
  position: absolute;
  top: 1px;
  right: 1px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.department-div {
  width: 100%;
  height: 28vh;
  margin-top: 2vh;
  cursor: pointer;
}

.department-row {
  margin-top: 35vh;
  margin-bottom: 35vh;
}



@media (max-width: 992px) {
  .department-row {
    margin-top: 20vh;
    margin-bottom: 20vh;
  }
}


@media (max-width: 768px) {
  .custom-footer {
    position: relative;
  }

  .department-row {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.department-div.bg-default:hover,
.department-div.bg-default:focus {
  background-color: #0b1526 !important;
}

.department-div.bg-payment:hover,
.department-div.bg-payment:focus {
  background-color:rgb(41, 86, 219) !important;
}

.department-div.bg-notification:hover,
.department-div.bg-notification:focus {
  background-color:rgb(0, 183, 122) !important;
}

.department-div.bg-bank:hover,
.department-div.bg-bank:focus {
  background-color:rgb(172, 52, 106) !important;
}


.custom-footer-2 {
  width: 100%;
  padding: 1.5rem 0 !important;
  box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
  clear: both;
  position: relative;
  margin-bottom: -200px;
  min-height: 10vh;
}


.min-window-height-100 {
  min-height: 90vh;
}
